import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faJs } from "@fortawesome/free-brands-svg-icons";
import { faCube, faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { Chip } from "@mui/material";
import "../../assets/styles/Expertise.scss";

const technologies = {
  javascript: [
    "JavaScript",
    "TypeScript",
    "NodeJS",
    "Bun",
    "DiscordJS",
    "SapphireJS",
    "ExpressJS",
    "QuickDB",
    "PrismaDB",
    "MySQL",
    "MongoDB",
    "ChatTriggers",
    "NPM",
    "Yarn",
    "PNPM",
    "ESLint",
    "Prettier",
    "Biome",
  ],
  minecraft: ["OpenAI", "Groq", "LangChain", "Qdrant", "Hugging Face", "LlamaIndex", "Streamlit"],
};

function Expertise() {
  return (
    <div className="container" id="expertise">
      <div className="skills-container">
        <h1>Expertise</h1>
        <div className="skills-grid">
          <Skill
            icon={faJs}
            title="JavaScript Development"
            description={`I've built many programs using different technologies like JavaScript and TypeScript, depending on what the project needs. For running my code, I rely on NodeJS or Bun. To manage my packages, I use tools like NPM, Yarn, PNPM, and Bun. I've used QuickDB and PrismaDB with databases like MySQL and MongoDB for storing data. For maintaining code quality, I use ESLint, Prettier, and Biome for linting and formatting. I have experience developing Discord bots using DiscordJS, with SapphireJS as my preferred framework. Additionally, I can create APIs using ExpressJS. I also can use the ChatTriggers framework to develop client-side Minecraft mods in JavaScript. These are just a few of the tools and frameworks I've worked with; they form the core of my project work and are what I use most frequently.`}
            chips={technologies.javascript}
          />
          <Skill
            icon={faIdBadge}
            title="Community Management & Staffing"
            description={`Once the application is built, I help clients set up DevOps testing, CI/CD pipelines, and deployment automation to support the successful Go-Live.`}
          />
          <Skill
            icon={faCube}
            title="Minecraft Configuration"
            description={`Stay relevant in the market by leveraging the latest AI models in your projects. I have professional experience building enterprise-grade GenAI-enabled solutions to empower intelligent decision-making.`}
            chips={technologies.minecraft}
          />
        </div>
      </div>
    </div>
  );
}

interface SkillProps {
  icon: any;
  title: string;
  description: string;
  chips?: string[];
}

const Skill: React.FC<SkillProps> = ({ icon, title, description, chips = [] }) => (
  <div className="skill">
    <FontAwesomeIcon icon={icon} size="3x" />
    <h3>{title}</h3>
    <p>{description}</p>
    {chips.length > 0 && (
      <div className="flex-chips">
        <span className="chip-title">Tech stack:</span>
        {chips.map(label => (
          <Chip key={label} className="chip" label={label} />
        ))}
      </div>
    )}
  </div>
);

export default Expertise;
