import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import "../../assets/styles/Contact.scss";

interface Props {
  parentToChild: {
    mode: string;
  };
}

const Contact: React.FC<Props> = ({ parentToChild }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { mode } = parentToChild;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    nameError: false,
    emailError: false,
    messageError: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { name, email, message } = formData;
    const errors = {
      nameError: name.trim() === "",
      emailError: email.trim() === "",
      messageError: message.trim() === "",
    };

    setFormErrors(errors);

    if (!errors.nameError && !errors.emailError && !errors.messageError) {
      const mailtoUrl = `mailto:contact@ahmadabdullah.com?subject=Message from ${encodeURIComponent(
        name
      )} (${encodeURIComponent(email)})&body=${encodeURIComponent(message)}`;
      window.open(mailtoUrl);
    }
  };

  return (
    <div id="contact" className={mode === "dark" ? "dark-mode" : ""}>
      <div className="items-container">
        <div className="contact_wrapper">
          <h1>Contact Me</h1>
          <p>Got a project waiting to be realized? Let's collaborate and make it happen!</p>
          <Box
            component="form"
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            className="contact-form"
          >
            <div className="form-flex">
              <TextField
                required
                id="name"
                label="Your Name"
                placeholder="What's your name?"
                value={formData.name}
                onChange={handleChange}
                error={formErrors.nameError}
                helperText={formErrors.nameError ? "Please enter your name" : ""}
                fullWidth
                variant="outlined"
                InputProps={{
                  className: mode === "dark" ? "input-dark" : "input-light",
                }}
                InputLabelProps={{
                  className: mode === "dark" ? "label-dark" : "label-light",
                }}
              />
              <TextField
                required
                id="email"
                label="Email / Phone"
                placeholder="How can I reach you?"
                value={formData.email}
                onChange={handleChange}
                error={formErrors.emailError}
                helperText={formErrors.emailError ? "Please enter your email or phone number" : ""}
                fullWidth
                variant="outlined"
                InputProps={{
                  className: mode === "dark" ? "input-dark" : "input-light",
                }}
                InputLabelProps={{
                  className: mode === "dark" ? "label-dark" : "label-light",
                }}
              />
            </div>
            <TextField
              required
              id="message"
              label="Message"
              placeholder="Send me any inquiries or questions"
              multiline
              rows={10}
              className="body-form"
              value={formData.message}
              onChange={handleChange}
              error={formErrors.messageError}
              helperText={formErrors.messageError ? "Please enter the message" : ""}
              fullWidth
              variant="outlined"
              InputProps={{
                className: mode === "dark" ? "input-dark" : "input-light",
              }}
              InputLabelProps={{
                className: mode === "dark" ? "label-dark" : "label-light",
              }}
            />
            <Button
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}
              className={mode === "dark" ? "button-dark" : "button-light"}
            >
              Send
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Contact;
