import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faGithub } from "@fortawesome/free-brands-svg-icons";
import "../../assets/styles/Footer.scss";

interface Props {
  parentToChild: {
    mode: string;
  };
}

const Footer: React.FC<Props> = ({ parentToChild }) => {
  const { mode } = parentToChild;
  const year = new Date().getFullYear();

  return (
    <footer className={mode === "dark" ? "dark-mode" : "light-mode"}>
      <div className="social-icons">
        <a
          href="https://github.com/ImAhmadAbdullah"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-link"
        >
          <FontAwesomeIcon icon={faGithub} size="2x" className="icon github" />
        </a>
        <a
          href="https://discord.com/users/994878326319624272"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-link"
        >
          <FontAwesomeIcon icon={faDiscord} size="2x" className="icon discord" />
        </a>
      </div>
      <p>
        © {year}{" "}
        <a href="/" className="footer-link">
          Ahmad Abdullah
        </a>
        . All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
