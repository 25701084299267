import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "@fortawesome/free-regular-svg-icons";
import "../../assets/styles/Timeline.scss";

const Timeline = () => {
  return (
    <div id="history">
      <div className="items-container">
        <h1>Work History</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "white", color: "rgb(39, 40, 34)" }}
            contentArrowStyle={{ borderRight: "7px solid white" }}
            date="April 2024 - June 2024"
            iconStyle={{ background: "#5000ca", color: "rgb(39, 40, 34)" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Moderator</h3>
            <h4 className="vertical-timeline-element-subtitle">BWHub Network</h4>
            <p>I was a part of BWHub Network's staff team.</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="July 2023 - August 2023"
            iconStyle={{ background: "#5000ca", color: "rgb(39, 40, 34)" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">PikaNerds Committee Member</h3>
            <h4 className="vertical-timeline-element-subtitle">PikaNetwork, CraftiGames B.V.</h4>
            <p>
              I was a member of the PikaNerds Committee. This was a team where members reported bugs
              and gave feedback to developers. They could suggest new features for updates to
              improve gameplay but couldn't decide when these updates would happen. They also got to
              join beta tests for their game mode, announced and managed by developers a few days
              before big updates or resets, to test new features and report bugs. On January 24,
              2024, the committee was replaced by a new invite-only group called the{" "}
              <a
                href="https://www.pika-network.net/crafti-council/"
                target="_blank"
                rel="noreferrer"
              >
                Crafti Council
              </a>
              , which does the same things.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021"
            iconStyle={{ background: "#5000ca", color: "rgb(39, 40, 34)" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Staff Engineer Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Laie, HI</h4>
            <p>Full-stack Development, API Development, User Experience</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2020"
            iconStyle={{ background: "#5000ca", color: "rgb(39, 40, 34)" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Data Analyst Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Tokyo, Japan</h4>
            <p>Automation, Data Governance, Statistical Analysis</p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Timeline;
