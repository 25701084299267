import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faGithub } from "@fortawesome/free-brands-svg-icons";
import "../../assets/styles/Main.scss";

interface Props {
  parentToChild: {
    mode: string;
  };
}

const Main: React.FC<Props> = ({ parentToChild }) => {
  const { mode } = parentToChild;

  return (
    <div className={mode === "dark" ? "dark-mode" : "light-mode"} id="main">
      <div className="about-section">
        <div className="image-wrapper">
          <img
            className={mode === "dark" ? "img-dark" : "img-light"}
            src={require("../../assets/images/pfp.png")}
            alt="Avatar"
          />
        </div>
        <div className="content">
          <div className="social-icons">
            <SocialIcon
              href="https://github.com/ImAhmadAbdullah"
              icon={faGithub}
              mode={mode}
              label="GitHub"
            />
            <SocialIcon
              href="https://discord.com/users/994878326319624272"
              icon={faDiscord}
              mode={mode}
              label="Discord"
            />
          </div>
          <div className="mobile-social-icons">
            <SocialIcon
              href="https://github.com/ImAhmadAbdullah"
              icon={faGithub}
              mode={mode}
              label="GitHub"
            />
            <SocialIcon
              href="https://discord.com/users/994878326319624272"
              icon={faDiscord}
              mode={mode}
              label="Discord"
            />
          </div>
          <h1>Ahmad Abdullah</h1>
          <p>I excel in developing, configuring, staffing, and managing projects.</p>
        </div>
      </div>
    </div>
  );
};

interface SocialIconProps {
  href: string;
  icon: any;
  mode: string;
  label: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ href, icon, mode, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`icon ${label.toLowerCase()}`}
  >
    <FontAwesomeIcon
      icon={icon}
      size="2x"
      className={`icon ${mode === "dark" ? "dark-mode" : "light-mode"}`}
    />
  </a>
);

export default Main;
