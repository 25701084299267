import { useState, useEffect } from "react";
import { Main, Timeline, Expertise, Contact, Navigation, Footer } from "./components";
import FadeIn from "./components/extra/FadeIn";
import "./index.scss";
import Cookies from "js-cookie";

function App() {
  const [mode, setMode] = useState<string>(Cookies.get("mode") || "light");

  const handleModeChange = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    Cookies.set("mode", newMode, { expires: 365 });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className={`main-container ${mode === "dark" ? "dark-mode" : "light-mode"}`}>
      <Navigation parentToChild={{ mode }} modeChange={handleModeChange} />
      <FadeIn transitionDuration={700}>
        <Main parentToChild={{ mode }} />
        <Expertise />
        <Timeline />
        <Contact parentToChild={{ mode }} />
      </FadeIn>
      <Footer parentToChild={{ mode }} />
    </div>
  );
}

export default App;
