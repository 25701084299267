import React, {
  useEffect,
  useState,
  ReactNode,
  PropsWithChildren,
  JSXElementConstructor,
} from "react";

interface Props {
  delay?: number;
  transitionDuration?: number;
  wrapperTag?: JSXElementConstructor<{ className?: string; children: ReactNode }>;
  childTag?: JSXElementConstructor<{
    className?: string;
    style?: React.CSSProperties;
    children: ReactNode;
  }>;
  className?: string;
  childClassName?: string;
  visible?: boolean;
  onComplete?: () => void;
}

const FadeIn: React.FC<PropsWithChildren<Props>> = ({
  delay = 50,
  transitionDuration = 400,
  wrapperTag: WrapperTag = "div",
  childTag: ChildTag = "div",
  className,
  childClassName,
  visible = true,
  onComplete,
  children,
}) => {
  const [maxIsVisible, setMaxIsVisible] = useState(0);

  useEffect(() => {
    let count = React.Children.count(children);
    if (!visible) {
      count = 0;
    }

    if (count === maxIsVisible) {
      const timeout = setTimeout(() => {
        if (onComplete) onComplete();
      }, transitionDuration);
      return () => clearTimeout(timeout);
    }

    const increment = count > maxIsVisible ? 1 : -1;
    const timeout = setTimeout(() => {
      setMaxIsVisible(prevMaxVisible => prevMaxVisible + increment);
    }, delay);
    return () => clearTimeout(timeout);
  }, [children, delay, maxIsVisible, visible, transitionDuration, onComplete]);

  return (
    <WrapperTag className={className}>
      {React.Children.map(children, (child, i) =>
        React.isValidElement(child) ? (
          <ChildTag
            className={childClassName}
            style={{
              transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
              transform: `translateY(${maxIsVisible > i ? "0" : "20px"})`,
              opacity: maxIsVisible > i ? 1 : 0,
            }}
          >
            {child}
          </ChildTag>
        ) : null
      )}
    </WrapperTag>
  );
};

export default FadeIn;
